import React from 'react'
import styles from './App.module.css'
import artwork from './artwork.png'
import ExternalLinkIcon from './ExternalLinkIcon'

const Link: React.FC<{ children: string; href: string }> = ({
    children,
    href,
}) => {
    return (
        <a href={href} className={styles.link}>
            <ExternalLinkIcon />
            {children}
        </a>
    )
}

function App() {
    return (
        <div className={styles.app}>
            <main>
                <img src={artwork} width="100%" alt="personal artwork" />
                <section className={styles.contact}>
                    <h1>Hey, how is it going?</h1>
                    <h3>Welcome to my personal website. </h3>
                    <p>
                        My name is Ludwig Schubert and I am a web developer from
                        Berlin, Germany.
                    </p>
                    <p>
                        I create websites and web applications. I also like
                        designing.
                    </p>
                    <p>
                        Do you have questions about my work or do you want to
                        collaborate?
                    </p>
                    <div className={styles.links}>
                        <Link href="mailto:heyludwigschubert@gmail.com">
                            Contact me via email
                        </Link>
                        <Link href="https://gitlab.com/ludwigschubi">
                            Check out my current projects on Gitlab
                        </Link>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default App
