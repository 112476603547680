import React from 'react'

interface ExternalLinkIconProps {}

const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = () => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.4731 13.7329V19.7329C18.4731 20.2633 18.2624 20.7721 17.8873 21.1471C17.5122 21.5222 17.0035 21.7329 16.4731 21.7329H5.47308C4.94265 21.7329 4.43394 21.5222 4.05887 21.1471C3.6838 20.7721 3.47308 20.2633 3.47308 19.7329V8.73291C3.47308 8.20248 3.6838 7.69377 4.05887 7.3187C4.43394 6.94362 4.94265 6.73291 5.47308 6.73291H11.4731"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4731 3.73291H21.4731V9.73291"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.4731 14.7329L21.4731 3.73291"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default ExternalLinkIcon
